/* TailwindCSS imports */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* CUSTOM CSS CLASSES */


.text-decoration-indigo {
  text-decoration-color: rgba(99, 102, 241);
  text-decoration-thickness: 2px;
}

/* CSS for modal */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position:fixed;
  background: white;
  width: 80%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.bottom-only-shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.06), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
